<template>
  <v-row dense>
    <v-col>
      <dispatch-table ref="dispatchTable" title="출동결과" :customers="customerOptions" @select="selectItem" />
      <dispatch-detail :taskItem="taskItem" :templateOptions="templateOptions" class="mt-2" @approved="approved" />
      <dispatch-proofs :taskItem="taskItem" class="mt-2" />
    </v-col>
  </v-row>
</template>

<script>
import api from '@/services/api'

export default {
  name: 'Dispatch',
  metaInfo: {
    title: '출동 결과 관리'
  },
  data() {
    return {
      customerOptions: [],
      taskItem: {},
      templateOptions: [],
    }
  },
  methods: {
    listCustomerOptions() {
      api.listCustomers().then(r => {
        this.customerOptions = r.result
        this.customerOptions.splice(0, 0, { custCode: null, custName: '전체' })
      })
    },
    listTemplateOptions() {
      api.getMessageTemplates().then(r => {
        this.templateOptions = r.result
      })
    },
    selectItem(taskItem) {
      if (taskItem) {
        this.taskItem = taskItem
      }
    },
    approved(taskItemId) {
      this.$refs.dispatchTable.list()
      this.taskItem = {}
      this.$forceUpdate()
    }
  },
  mounted() {
    this.listCustomerOptions()
    this.listTemplateOptions()
  },
  created() {
    this.$store.commit('setAppBarTitle', '출동 결과 관리')
  },
  components: {
    DispatchTable: () => import('@/views/components/dispatch/DispatchTable'),
    DispatchDetail: () => import('@/views/components/dispatch/DispatchDetail'),
    DispatchProofs: () => import('@/views/components/dispatch/DispatchProofs')
  }
}
</script>
